//icons
// import { Monitor } from "@styled-icons/feather/Monitor";
import { MakeGroup } from "@styled-icons/icomoon/MakeGroup";
import { Input } from "@styled-icons/material/Input";
import { TestTube } from "@styled-icons/boxicons-regular/TestTube";
import { UserLock } from "@styled-icons/fa-solid/UserLock";
import { Coupon2 } from "@styled-icons/remix-line/Coupon2";
import * as Dash from "@styled-icons/remix-line/Dashboard";
// import { Link } from "@styled-icons/boxicons-regular/Link";
import { Users } from "@styled-icons/fa-solid/Users";
import { Settings2Outline } from "@styled-icons/evaicons-outline/Settings2Outline";
// import React from "react";
// import { UserContext } from "@xai/providers/with-auth";
import { FetchSupersetRoutesService } from "@xai/services/superset-integration";
import { ChartPie } from "@styled-icons/heroicons-outline/ChartPie";
import { ChartPieOutline } from "@styled-icons/typicons/ChartPieOutline";
import { DataPie } from "@styled-icons/fluentui-system-regular/DataPie";
// import { Coupon2 } from "@styled-icons/remix-line/Coupon2";
// import { Link } from "@styled-icons/boxicons-regular/Link";
// import { Users } from "@styled-icons/fa-solid/Users";
import { Database } from "@styled-icons/feather/Database";
// import { Admin } from "@styled-icons/remix-line/Admin";
// import { MonitorOutline } from "@styled-icons/evaicons-outline/MonitorOutline";
// import { Station } from "@styled-icons/boxicons-regular/Station";
// import { Clipboard } from "@styled-icons/boxicons-regular";
// import { ChartBar } from "@styled-icons/fa-regular";
// import { Dashboard } from "@styled-icons/material-outlined/Dashboard";
import { JournalText } from "@styled-icons/bootstrap/JournalText";
import { Flask } from "@styled-icons/boxicons-solid/Flask";
import { Flight } from "@styled-icons/material";
import { Link45deg } from "@styled-icons/bootstrap/Link45deg";
import { ListAlt } from "@styled-icons/material-outlined/ListAlt";

const iconMapper = (iconText) => {
  switch (iconText) {
    case "UserLock":
      return UserLock;
    case "Dash.Dashboard":
      return Dash.Dashboard;
    case "ArrowTrendingSettings":
      return Settings2Outline;
    case "Input":
      return Input;
    case "MakeGroup":
      return MakeGroup;
    case "Coupon2":
      return Coupon2;
    case "TestTube":
      return TestTube;
    case "Users":
      return Users;
    case "ChartPie":
      return ChartPie;
    case "ChartPieOutline":
      return ChartPieOutline;
    case "DataPie":
      return DataPie;
    case "Database":
      return Database;
    case "JournalText":
      return JournalText;
    case "Flask":
      return Flask;
    case "Flight":
      return Flight;
    case "Link45deg":
      return Link45deg;
    case "ListAlt":
      return ListAlt;
    default:
      return Input;
  }
};

export const StaticRouteConfig = {
  groups: [
    {
      name: "Executive",
      items: [
        {
          text: "At a Glance",
          icon: "Dashboard",
          linked: "dashboard",
          key: "Dashboard",
          defaultRoute: "overview",
          subMenu: [
            {
              name: "Overview",
              linked: "overview",
              key: "ExecutiveAtAGlanceOverview",
            },
            // {
            //   name: "Calendar",
            //   linked: "/calendar",
            //   key: "Calendar",
            // },
          ],
        },
        {
          text: "Enterprise Ledger",
          icon: "JournalText",
          linked: "experiment-ledger",
          key: "Enterprise Ledger",
          defaultRoute: "summary",
          subMenu: [
            {
              name: "Summary",
              linked: "summary",
              key: "ExecutiveEnterpriseLedger",
            },
          ],
        },
      ],
    },
    {
      name: "Design",
      items: [
        {
          text: "Experiment",
          icon: "Flask",
          // linked: "/testing", // #TODO - to be changed to  /design, will need DB changes as well to handle user access
          linked: "design",
          key: "Experiment",
          defaultRoute: "experiment",
          subMenu: [
            {
              name: "Experiment",
              linked: "experiment",
              key: "DesignExperiment",
            },
            {
              name: "Variant Bank",
              linked: "variant-bank-approval",
              key: "DesignVariantBankApproval",
            },
            {
              key: "DesignMaxAIApplications",
              linked: "gen-ai",
              name: "Generative AI",
            },
            {
              key: "DesignGenAIGallery",
              linked: "gallery",
              name: "Gallery",
            },
            {
              key: "DesignGenAIArchive",
              linked: "archive",
              name: "Archive",
            },
            {
              key: "DesignGenAIDynamicImages",
              linked: "new",
              name: "",
            },
          ],
        },
        {
          text: "Optimization",
          icon: "Input",
          // linked: "/businessinputs", // #TODO - to be changed to  /improve, will need DB changes as well to handle user access
          linked: "improve",
          key: "Improve",
          defaultRoute: "optimization",
          subMenu: [
            {
              name: "Optimization",
              linked: "optimization",
              key: "DesignOptimization",
            },
            {
              name: "AI Sequencing",
              linked: "aisequencing",
              key: "DesignAISequencing",
            },
          ],
        },
      ],
    },
    {
      name: "Measure",
      items: [
        {
          text: "Test Library",
          icon: "Flight",
          // linked: "/classification", // #TODO - to be changed to  /tests, will need DB changes as well to handle user access
          linked: "tests",
          key: "Tests",
          defaultRoute: "list",
          subMenu: [
            {
              name: "Test Library",
              linked: "list",
              key: "MeasureTestLibrary",
            },
            // {
            //   name: "Analyze",
            //   linked: "/analyze",
            //   key: "MeasureAnalyze",
            // },
          ],
        },
        {
          text: "Results",
          icon: "TestTube",
          // linked: "/recommendation", // #TODO - to be changed to  /measure, will need DB changes as well to handle user access
          linked: "measure",
          key: "Measure",
          defaultRoute: "results",
          subMenu: [
            {
              name: "Results",
              linked: "results",
              key: "MeasureResults",
            },
          ],
        },
      ],
    },
    {
      name: "Data",
      items: [
        {
          text: "Data Library",
          icon: "Database",
          linked: "data", // #TODO - to be changed to  /data, will need DB changes as well to handle user access
          key: "Data Library",
          defaultRoute: "import",
          subMenu: [
            {
              name: "Import",
              linked: "import",
              key: "DataData",
            },
          ],
        },
        {
          text: "Integrations",
          icon: "Link45deg",
          // linked: "/infrastructure", // #TODO - to be changed to  /integrations, will need DB changes as well to handle user access
          linked: "integrations",
          key: "Integrations",
          defaultRoute: "integrations",
          subMenu: [
            {
              name: "Integrations",
              linked: "integrations",
              key: "DataIntegrations",
            },
          ],
        },
        // {
        //   text: "Custom Reports",
        //   icon: "DataPie",
        //   linked: "customReports",
        //   key: "Custom Reports",
        //   defaultRoute: "reports",
        //   subMenu: [
        //     {
        //       name: "Reports",
        //       linked: "reports",
        //       key: "CustomReports",
        //       // dummy: true,
        //     },
        //   ],
        // },
      ],
    },
    {
      name: "Administration",
      last: true,
      items: [
        {
          text: "Task Management",
          icon: "ListAlt",
          // linked: "/issuesactions", // #TODO - to be changed to  /experiment-tasks, will need DB changes as well to handle user access
          linked: "experiment-tasks",
          key: "Task Management",
          defaultRoute: "managementdashboard",
          subMenu: [
            {
              name: "Task management",
              linked: "managementdashboard",
              key: "ExperimentTasksManagementDashboard",
            },
          ],
        },
        {
          text: "Access Control",
          icon: "UserLock",
          linked: "accesscontrol",
          key: "Access Control",
          defaultRoute: "accesscontrol",
          subMenu: [
            {
              name: "Access Control",
              linked: "accesscontrol",
              key: "AccessControl",
            },
          ],
        },
        // {
        //   text: "Configure Superset",
        //   icon: "ArrowTrendingSettings",
        //   linked: "configurable",
        //   defaultRoute: "superset",
        //   subMenu: [
        //     {
        //       name: "Superset",
        //       linked: "superset",
        //       key: "SupersetIntegration",
        //     },
        //   ],
        // },
      ],
    },
  ],
};

export const FetchSupersetRoutes = async (token) => {
  let res = await FetchSupersetRoutesService(token);
  console.log("superset routes", res);
  return res;
};

export const MenuConfig2 = async (/* token */) => {
  let data = JSON.parse(JSON.stringify(StaticRouteConfig));
  // let ssData = await FetchSupersetRoutes(token);
  // ssData.filter(x => x.route !== "Add as Report").map((ss) => {
  //   return data.groups.map((config) => {
  //     if (config.name === ss.route.split("|||||")[1]) {
  //       config.items[0].subMenu.push({
  //         isSupersetRoute: true,
  //         linked: ss.subroute,
  //         key: ss.subroute,
  //         name: ss.subroute,
  //         dashboardId: ss.dashboard_id,
  //       });
  //     }
  //   });
  // });
  console.log("after appending ss data", data, /* ssData */);

  data = {
    ...data,
    groups: data.groups.map((_) => ({
      ..._,
      items: _.items.map((e) => ({
        ...e,
        icon: iconMapper(e.icon),
      })),
    })),
  };

  return data;
};

export const FeedbackProps = {
  mailId: "pai.support@zs.com",
  defaultSubj: "Feedback for Personalize.AI",
};
